$utilities: (
    full-width: (width: 100%),

    hide: (display: none),
    show: (display: block),

    indent: (margin-left: 16px),
    indent-double: (margin-left: 32px),

    semi-bold: (font-weight: 600),

    limit-width: (max-width: 620px),

    visually-hidden: (position: absolute, width: 1px, height: 1px, overflow: hidden, clip: rect(1px, 1px, 1px, 1px), white-space: nowrap),
);

@mixin renderUtilities($prefix: \-\-) {
    @each $name, $rules in $utilities {
        .#{$prefix}#{$name} {

            @each $key,
            $value in $rules {
                #{$key}: $value;
            }
        }
    }
}

@include renderUtilities;

@each $name, $size in $breakpoints {
    @include media(#{$name}) {
        @include renderUtilities(#{$name}\:\-\-);
    }
}
