.modal {
    backdrop-filter: blur(2px);
    display: block;

    &-header {
        background: $lightgrey;
        border: none;
    }

    &-title {
        font-size: 1rem;
        font-weight: 600;
    }

    &-body {
        font-size: 0.875rem;
    }
    
    &-label {
        &--light{
            color:#6D7176;
        }
    }
  

    @include media($until: md) {
        &-content {
            @include position(fixed, 52px 0 0 0);
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }

        &-header {
            background: $white;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            text-align: center;
            padding-top: 30px;
            border-bottom: 1px solid $input-border-color;
        }

        &-footer {
            border-top: none;
            display: flex;
            flex-direction: column-reverse;

            .btn {
                margin: 0;
                width: 100%;

                &:last-child {
                    margin-bottom: 12px;
                }
            }
        }

        &-title {
            font-size: 1.2rem;
            width: 100%;
        }
    }
}
