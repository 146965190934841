@import '../../../scss/module.scss';

.container {
    display: flex;
    flex: 1 0 auto;
    width: 240px;
}

.file {
    background: none;
    border: none;
    font-size: 13px;
    white-space: nowrap;
    text-align: left;
}

.icon {
    fill: $basered;
}
