@import '../../../scss/module.scss';

.banner {
    align-items: flex-start;
    background: #ffeeaa;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    box-shadow: 0 10px 20px 0 rgb(42 48 55 / 10%);
    color: $dark;
    display: flex;
    gap: 12px;
    margin: 6px;
    overflow: hidden;
    padding: 24px;
    user-select: none;

    &__heading {
        font-weight: bold;
        margin-bottom: 6px;
    }

    &__text {
        flex: 1;

        p {
            margin: 0 0 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__close {
        background: none;
        border: none;
        color: $dark;
        font-weight: 600;
    }
}