@import '../../../scss/module.scss';

.container {
    background: none;
    border: none;
    padding: 4px;
    text-align: left;

    & + & {
        margin-top: 4px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.time {
    font-size: 12px;
    line-height: 12px;
}

.name {
    font-size: 12px;
}