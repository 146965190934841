@import '../../../scss/module.scss';

.container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto 24px;
    max-width: 400px;
}

.date {
    font-size: 18px;
    font-weight: 600;
    min-width: 180px;
    text-align: center;
}

.button {
    background: none;
    border: none;

    &::before {
        @include chevron(14px, $primary, 2px);
        content: "";
    }
}

.left::before {
    transform: rotate(135deg);
}

.right::before {
    transform: rotate(-45deg);
}