@import '../../scss/module.scss';

.blocker {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(255, 255, 255, 0.5);
}

.preamble {
    text-align: center;
    max-width: 700px;
    margin: -32px auto 24px;
}