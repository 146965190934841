.cover {
    @include position(fixed, 0 0 0 0);
    backdrop-filter: blur(2px);
    background: rgb(10, 17, 25, 0.9);
    z-index: 1000;

    &__content {
        color: $white;
        margin: 50vh auto 0;
        max-width: 464px;
        text-align: center;
        transform: translateY(-50%);

        @include media($until: md) {
            padding: 0 24px;
        }
    }

    &__heading {
        font-size: 1.375rem;
        font-weight: 600;
        margin-bottom: 24px;
    }

    &__text {
        margin-bottom: 32px;
    }

    .btn-secondary {
        border-color: $white;
        color: $white;

        &:hover, &:active, &:focus {
            border-color: $basered-hover;
        }
    }
}