﻿//--------------------------------------------------------------------------------------------------------------------------------------
// MEDIA QUERY MIXIN
//--------------------------------------------------------------------------------------------------------------------------------------
@use "sass:math";

/*
SASS for "Simplifying media queries with SASS" Web Designer Magazine Tutorial

Example usages:

$breakpoints: (
	sm: 320px,
	md: 480px
);

@include media(sm, md) { }

@include media($from: sm, $until: md) { }

@include media($until: md) { }

@include media(sm, md, '(orientation:portrait)', 'only screen') { }

@include media(200px, 400px) { }

*/


// DEFINE SETTINGS
//--------------------------------------------------------------------------------------------------------------------------------------

// Set default breakpoints
$breakpoints: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xlg: 1200px
) !default;

// Font-size value on `<body>` element
$browser-font-size: 16px !default;


// PROCESS VALUES FUNCTION
//--------------------------------------------------------------------------------------------------------------------------------------

// Function to process given breakpoint which returns a value
@function mq-process($value) {
	// Check if we have a numeric value
	@if type-of($value) == 'number' {
		// Check if PX value
		@if index('px', unit($value)) != null {
			// Convert to EM unit
			@return math.div($value, $browser-font-size) * 1em;
		}
		// Otherwise check if EM value
		@else if index('em', unit($value)) != null {
			// Do nothing, just return EM value as is
			@return $value;
		}
		// Otherwise throw error
		@else {
			@error "`#{$value}` needs to be either PX, EM or a value from $breakpoints";
		}
	}
	// Otherwise check `$breakpoints` map for a matching entry
	@else if map-has-key($breakpoints, $value) {
		// Grab value
		$value: map-get($breakpoints, $value);
		// Convert to EM unit
		@return math.div($value, $browser-font-size) * 1em;
	}
	// If no match, throw error
	@else {
		@error "`#{$value}` doesn't exist in $breakpoints";
	}
}

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
    @if map-has-key($breakpoints, $name) {
        @return map-get($breakpoints, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
    }
}


// DEFINE MIXIN
//--------------------------------------------------------------------------------------------------------------------------------------

@mixin media($from: false, $until: false, $and: false, $type: false){
	// Check if anything is set, if not throw an error
	@if $from == false and $until == false and $and == false and $type == false {
		@error "You haven't passed anything";
	}

	// Process $from and $until if present
	@if $from {
		// Process value, returns EM
		$from: mq-process($from);
	}
	@if $until {
		// Process value, returns EM
		$until: mq-process($until);
		// Remove .01em $from until so it doesn't conflict with any media queries starting from that breakpoint
		$until: $until - .01em;
	}

	// Declare variable
	$mq: false;

	// Compose media query ranges
	// If we have both $from and $to values
	@if $from and $until {
		$mq: '(min-width: #{$from}) and (max-width: #{$until})';
	}
	// If we have just a $from value
	@else if $from {
		$mq: '(min-width: #{$from})';
	}
	// If we have just an $until value
	@else if $until {
		$mq: '(max-width: #{$until})';
	}

	// If $and passed and have mq values
	@if $and and $mq {
		$mq: '#{$mq} and #{$and}'
	}
	// If only $and has been passed
	@else if $and {
		$mq: '#{$and}'
	}

	// If $type passed and we have mq values
	@if $type and $mq {
		$mq: '#{$type} and #{$mq}';
	}
	// If only $type has been passed
	@else if $type {
		$mq: '#{$type}';
	}

	// Print media query
	@media #{$mq} {
		@content;
	}
}

@mixin show-breakpoints() {
    body::before {
        background-color: #FCF8E3;
        border-bottom: 1px solid #FBEED5;
        border-left: 1px solid #FBEED5;
        color: #C09853;
        font: small-caption;
        padding: 3px 6px;
        pointer-events: none;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1001;

        // Loop through the breakpoints that should be shown
        @each $breakpoint in $breakpoints {
            $name: nth($breakpoint, 1);
            $width: nth($breakpoint, 2);
            @include media($name) {
                content: "#{$name} ≥ #{$width}";
            }
        }
    }
}

@mixin javascript-breakpoints() {
    body::after {
        content: "mobile";
        display: none;

        @each $breakpoint in $breakpoints {
            $name: nth($breakpoint, 1);
            $width: nth($breakpoint, 2);

            @include media($name) {
                content: "#{$name}";
            }
        }
    }
}