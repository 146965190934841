.custom-table {
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.875rem;
    margin-bottom: 100px;
    overflow-x: auto;
    width: 100%;

    @include media(md) {
        min-width: 600px;
    }

    &__head {
        background: $black-6;
        border-bottom: 1px solid $input-border-color;
    }

    &__header {
        padding: 20px 8px;

        &:first-child {
            padding-left: 12px;

            @include media(md) {
                padding-left: 36px;
            }
        }

        &:last-child {
            padding-right: 12px;

            @include media(md) {
                padding-right: 36px;
            }
        }
    }

    &__row {
        cursor: pointer;

        &:hover {
            background: $light;
        }

        td {
            padding: 16px 8px;
            border-bottom: 1px solid $input-border-color;

            &:first-child {
                padding-left: 12px;

                @include media(md) {
                    padding-left: 36px;
                }
            }
            
            &:last-child {
                padding-right: 12px;

                @include media(md) {
                    padding-right: 36px;
                }
            }
        }
    }

    &__options {
        width: 24px;
    }
}