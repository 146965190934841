@import '../../../scss/module.scss';

.container {
    @include position(fixed, 0 0 0 0);
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex: 1;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.content {
    background: #f1f1f2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
    overflow-y: auto;
    padding: 50px;
    width: 50%;

    
    @media (max-width: 767px) {
        padding: 25px;
        width: 100%;
    }
}

.text {
    margin: 0 auto;
    max-width: 480px;
    padding: 20px 0 80px;
    width: 100%;

    p {
        margin: 10px 0 30px;
    }

    button {
        display: block;
        width: 100%;
        margin: 0;

        & + button {
            margin: 12px 0 0 !important;
        } 
    }
}

.heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.footer {
    font-size: 14px;
    text-align: center;

    a {
        color: #0056B3;
    }
}

.image {
    background: url("~/public/images/backdrop.jpg") no-repeat center center;
    background-size: cover;
    width: 50%;
    
    @media (max-width: 767px) {
        display: none;
    }
}
