@import '../../../scss/module.scss';

$border: 1px solid $input-border-color;

.container {
    display: flex;
    justify-content: flex-end;
    margin: -36px 0 0 0;
    position: relative;
}

.button {
    background: none;
    border: none;
    font-weight: bold;
    padding-right: 40px;

    &::before {
        @include chevron(12px, $dark, 1px);
        @include position(absolute, 4px 8px null null);
        content: "";
        transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;
    }

    &--expanded {
        &::before {
            transform: scaleY(-1) rotate(45deg);
            top: 10px;
        }
    }
}

.dropdown {
    @include position(absolute, 28px 0 null 0);
    background: #fff;
    border-left: $border;
    border-right: $border;
    border-bottom: $border;
    box-shadow: 0 10px 12px rgba(0,0,0,0.05), 0 2px 4px -2px rgba(0,0,0,0.1);
    padding: 12px;
    z-index: 1000;

    @include media(md) {
        display: flex;
        flex-wrap: wrap;
    }
}

.list {
    @include media(md) {
        width: 25%;
    }
}

.title {
    background: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 0;
    text-transform: capitalize;
}

.buttongroup {
    margin-top: 8px;
    width: 100%;

    @include media(sm) {
        display: flex;
        justify-content: flex-end;
    }

    @include media($until: sm) {
        > * {
            margin-bottom: 4px;
            width: 100%;
        }
    }
}