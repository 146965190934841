.file {
    border: 1px solid $midgrey;
    border-radius: 5px;
    margin-top: 24px;
    display: flex;
    padding: 20px 16px 20px 54px;
    position: relative;

    &::before {
        @include size(21px, 28px);
        @include position(absolute, 24px null null 16px);
        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="21" height="28"><path fill="#D51C29" d="M369.9 97.9 286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-22-171.2c-13.5-13.3-55-9.2-73.7-6.7-21.2-12.8-35.2-30.4-45.1-56.6 4.3-18 12-47.2 6.4-64.9-4.4-28.1-39.7-24.7-44.6-6.8-5 18.3-.3 44.4 8.4 77.8-11.9 28.4-29.7 66.9-42.1 88.6-20.8 10.7-54.1 29.3-58.8 52.4-3.5 16.8 22.9 39.4 53.1 6.4 9.1-9.9 19.3-24.8 31.3-45.5 26.7-8.8 56.1-19.8 82-24 21.9 12 47.6 19.9 64.6 19.9 27.7.1 28.9-30.2 18.5-40.6zm-229.2 89c5.9-15.9 28.6-34.4 35.5-40.8-22.1 35.3-35.5 41.5-35.5 40.8zM180 175.5c8.7 0 7.8 37.5 2.1 47.6-5.2-16.3-5-47.6-2.1-47.6zm-28.4 159.3c11.3-19.8 21-43.2 28.8-63.7 9.7 17.7 22.1 31.7 35.1 41.5-24.3 4.7-45.4 15.1-63.9 22.2zm153.4-5.9s-5.8 7-43.5-9.1c41-3 47.7 6.4 43.5 9.1z"/></svg>') no-repeat;
        content: "";
        display: block;
    }

    &--reverse{
        border: none;
        box-shadow: 0 8px 12px rgba(42, 48, 55, 0.1), 0 0px 4px rgba(42, 48, 55, 0.1);
        font-weight: bold;
        padding: 20px 54px 20px 16px;

        &::before {
            @include position(absolute, 18px 16px null auto);
        }
    }

    &--no-icon {
        padding-left: 16px;
        
        &::before {
            display: none;
        }
    }

    &__content {
        flex: 1;
    }

    &__link {
        color: $dark;
        font-weight: bold;
        display: block;
        text-decoration: none;

        &:hover {
            color: $dark;
            text-decoration: underline;
        }
    }

    &__delete {
        background: inherit;
        border: none;
        color: $basered;
        font-size: 0.75rem;
        font-weight: 600;

        &::after {
            @include size(14px, 16px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14" height="16"><path fill="#D51C29" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"/></svg>') no-repeat;
            content: "";
            display: inline-block;
            margin-left: 8px;
            vertical-align: text-top;
        }
    }
}