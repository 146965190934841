.native-menu {
    @include position(fixed, 0 0 0 0);
    background: rgba(4,4,15,0.4);
    z-index: 1000;

    &__content {
        @include position(absolute, null 0 0 0);
        padding: 10px;
    }

    &__button {
        background: $white;
        border: none;
        border-radius: 14px;
        color: $system-blue-light;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1;
        margin-top: 10px;
        padding: 20px 0;
        text-align: center;
        width: 100%;
    }

    &__actions {
        border-radius: 14px;
        background: $light;
    }

    &__action {
        background: transparent;
        border-bottom: 1px solid $input-border-color;
        border: none;
        color: $system-blue-light;
        display:block;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 20px;
        text-align: center;
        text-align: center;
        width: 100%;
    }
}