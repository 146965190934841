.bookings {
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.875rem;
    margin-bottom: 100px;
    overflow-x: auto;
    width: 100%;

    @include media(md) {
        min-width: 600px;
    }

    &__head {
        background: $black-6;
        border-bottom: 1px solid $input-border-color;
    }

    &__header {
        cursor: pointer;
        padding: 20px 8px;
        user-select: none;

        &:first-child {
            padding-left: 36px;
        }

        &--ascending::after,
        &--descending::after,
        &--no-sort:hover::after {
            @include size(8px, 19px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="8" height="13"><path fill="#0A1119" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>') no-repeat center center;
            content: "";
            display: inline-block;
            margin-left: 5px;
            vertical-align: text-top;
        }

        &--descending::after {
            transform: rotate(180deg);
        }

        &--no-sort:hover::after {
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="8" height="13"><path fill="#A2A4A8" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>') no-repeat center center;
        }
    }

    &__row {
        cursor: pointer;

        &:hover {
            background: $light;
        }

        td {
            padding: 16px 8px;
            border-bottom: 1px solid $input-border-color;

            &:first-child {
                padding-left: 36px;
            }
        }
    }

    &__status {
        width: 81px;
        color: white;
        margin-right: 24px;
    }

    &__phone-number {
        color: #2a3037;
        text-decoration: none;
    }

    &__filter {
        .checkdown__button {
            border: 1px solid $input-border-color;
            box-shadow: none;
        }
    }
}