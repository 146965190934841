.conversations {
    border-top: 1px solid $black-12;
    display: flex;
    height: calc(100vh - 205px);
    margin: 0 -32px -8px;
    min-height: 500px;
    overflow: hidden;

    @include media(md) {
        min-width: 800px;
    }

    &__icon {
        @include size(56px);
        background-color: #d5d5d8;
        border-radius: 50%;
        display: grid;
        font-size: 18px;
        font-weight: 600;
        min-width: 56px;
        object-fit: cover;
        overflow: hidden;
        place-items: center;

        &--home {
            background: no-repeat center svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="27" height="24"><path fill="#D51C29" d="M541 229.16l-232.85-190a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"/></svg>');
            background-color: rgba(213, 28, 41, 0.19);
        }
        
        &--group {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"/></svg>');
        }

        &--user {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c61.8 0 112 50.2 112 112s-50.2 112-112 112-112-50.2-112-112S194.2 32 256 32m128 320c52.9 0 96 43.1 96 96v32H32v-32c0-52.9 43.1-96 96-96 85 0 67.3 16 128 16 60.9 0 42.9-16 128-16M256 0c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144S335.5 0 256 0zm128 320c-92.4 0-71 16-128 16-56.8 0-35.7-16-128-16C57.3 320 0 377.3 0 448v32c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-32c0-70.7-57.3-128-128-128z"/></svg>');
        }

        &--large {
            @include size(88px);
            font-size: 28px;
            min-width: 88px;
        }
        
        &--small {
            @include size(24px);
            font-size: 8px;
            min-width: 24px;
        }
    }

    &__info {
        font-size: 14px;
        margin-bottom: -6px;
        padding-top: 8px;
    }

    &__list {
        border-right: 1px solid $black-12;
        box-shadow: 10px 0px 10px 0px rgb(42 48 55 / 10%);
        max-height: 100%;
        min-width: 330px;
        overflow-y: auto;
        position: relative;
        width: 330px;

        &-item {
            height: 96px;
            margin: 0;
            max-height: 96px;
            padding: 0 0 0 24px;
            
            &--current {
                background-color: $pink;
            }
            
            &-inner {
                border-bottom: 1px solid $black-12;
                display: flex;
                height: 100%;
                margin: 0;
                padding: 16px 16px 16px 0;
            }
            
            & h3 {
                max-width: 140px;
            }
            
            & p {
                max-width: 216px !important;
            }
        }
    }

    &__item {
        background: #f7f8f8;
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        justify-content: space-between;
        
        &-header {
            align-items: center;
            background: $white;
            box-shadow: 0px 10px 10px 0px rgb(42 48 55 / 10%);
            display: flex;
            height: 95px;
            padding: 16px;
            position: relative;
            
            &-icon {
                @include size(56px);
                background-color: #d51c29;
                border-radius: 50%;
                margin-right: 16px;
                object-fit: cover;
                overflow: hidden;
            }
        }

        &-message {
            background: $white;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            box-shadow: 0px -10px 10px 0px rgb(42 48 55 / 10%);
            padding: 7px 24px 24px;
            position: relative;
        }
    }

    &__send-button {
        @include size(18px);
        @include position(absolute, null 40px 40px null);
        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18"><path fill="#D51C29" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/></svg>') no-repeat center;
        border: none;
        cursor: pointer;

        &:disabled {
            background: transparent;

            &::before {
                @include position(absolute, -6px -6px null null);
                @include size(30px);
                box-sizing: border-box;
                border: solid 4px transparent;
                border-top-color: $primary;
                border-left-color: $primary;
                border-radius: 50%;
                content: "";
            
                -webkit-animation: nprogress-spinner 400ms linear infinite;
                animation: nprogress-spinner 400ms linear infinite;
            }
        }
    }

    &__message-list {
        height: calc(100% - 191px);
        max-height: calc(100% - 191px);
        overflow-y: auto;
        
        &-item {
            background: transparent;
            border-radius: 4px;
            padding: 16px 32px;
            width: 100%;

            &-content {
                border-radius: 4px;
            }

            &--bg-grey {
                background-color: rgb(224, 226, 227);
            }
        }
    }

    &__input {
        overflow-y: hidden;
        padding-right: 44px;
        resize: none;
    }

    &__shadow-input {
        height: 50px;
        padding-right: 44px;
        overflow-y: hidden;
        position: absolute;  
        resize: none;
        visibility: hidden;
        width: calc(100% - 48px);
    }

    &__new-group {
        margin: 8px 16px;
        display: block;
        width: calc(100% - 32px);
    }
}
