@import '../../../scss/module.scss';

.container {
    display: inline-block;
    position: relative;
    
    :global {
        .react-calendar {
            position: absolute;
            width: 348px;
            max-width: 100vw;
            transform: translate(-50%, 0);
            z-index: 2;
        }
    }
}

.button {
    @include size(23px);
    border: none;
    transform: translate(8px, 2px);
    background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="23" height="23"><path fill="#D51C29" d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"/></svg>') no-repeat;
}