html {
    box-sizing: border-box;
}

button,
body {
    color: $dark;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.visuallyhidden {
    @include size(1px);
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.desktop-only {
    @include media($until: md) {
        display: none !important;
    }
}

.mobile-only {
    @include media(md) {
        display: none !important;
    }
}

label {
    user-select: none;
}

.block-ui {
    @include position(fixed, 0 0 0 0);
    background: rgba(255, 255, 255, 0.8);
    z-index: 1001;
}
.w-140px {
  width: 140px;
}
.mw-140px {
  max-width: 140px;
}
.w-180px {
    width: 180px;
}

@include media(md) {
    .width-50 {
        width: 50%;
    }    

    .width-33 {
        width: 33%;
    }    

    .width-25 {
        width: 25%;
    }    

    .width-20 {
        width: 20%;
    }    
}

.hover-link {
    color: $basered;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.list-striped {
    margin: 0 -16px;
    padding: 8px 16px;

    &:nth-child(even) {
        background: rgba(0,0,0,0.03);
    }
}

.list-hover {
    cursor: pointer;
    margin: 0 -16px;
    padding: 8px 16px;

    &:nth-child(even) {
        background: rgba(0,0,0,0.03);
    }

    &:hover {
        background: $basered;
        border-color: transparent !important;
        color: #fff;
    }
}

.list-marked {
    &--read {
        border-left: 8px solid $success;
        padding-left: 8px;
    }

    &--partial {
        border-left: 8px solid #ffcc00;
        padding-left: 8px;
    }

    &--unread {
        border-left: 8px solid $error;
        padding-left: 8px;
    }
}