@import '../../../scss/module.scss';

.container {
    background: $lightgrey;
    display: flex;
    padding: 12px;
    overflow: auto;
    user-select: none;
}

.image {
    @include size(100px);
    margin-right: 12px;
    object-fit: cover;
}