$tooltip-color: $dark;

.custom-tooltip {
    @include size(18px);
    border-radius: 9px;
    border: 2px solid $dark;
    color: $dark;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    margin: 0 8px;
    text-align: center;

    &::before {
        content: "i";
    }

    &__content {
        background: $tooltip-color;
        border-radius: 3px;
        color: #fff;
        display: none;
        font-size: 14px;
        font-weight: normal;
        max-width: 240px;
        padding: 5px 10px;
        position: absolute;
        transform: translateY(calc(-100% - 30px)) translateX(calc(-50% + 9px));
        width: fit-content;
        z-index: 3;
    
        &::before {
            @include arrow(bottom, $tooltip-color, 8px, true);
        }

        .custom-tooltip:hover & {
            display: block;
        }
    }
}

