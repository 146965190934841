@import '../../../scss/module.scss';

.container {
    border-left: 1px solid $input-border-color;
    border-radius: 5px 5px 0 0;
    border-right: 1px solid $input-border-color;
    border-top: 1px solid $input-border-color;
    display: flex;
    justify-content: center;
    margin: 6px 0 -4px;
    padding: 12px 0;
    position: relative;
}

.image {
    @include size(200px, 100px);
    object-fit: contain;
}

.remove {
    @include position(absolute, 8px 8px null null);
    background: $black-60;
    border: none;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
}