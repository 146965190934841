@import '../../../scss/module.scss';

.badge {
    background-color: #b6e8a8;
    border: none;
    border-radius: 18px;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 1px 24px;
    user-select: none;

    &--unread {
        background-color: $pink;
    }
}

.button {
    @include size(24px);
    background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10a2 2 0 102 2 2 2 0 00-2-2zm-7 0a2 2 0 102 2 2 2 0 00-2-2zm14 0a2 2 0 102 2 2 2 0 00-2-2z"/></svg>') no-repeat center center;
    border: 0;
    display: block;
    margin-left: 8px;
    user-select: none;
}

.options {
    @include position(absolute, auto 0 auto auto);
    background: #fff;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    box-shadow: 2px 4px 12px rgba(0,0,0,0.1);
    padding: 8px;
    user-select: none;
    z-index: 1000;
}

.option {
    background-color: transparent;
    border: 0;
    white-space: nowrap;
}