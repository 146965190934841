@import '../../scss/module.scss';

.row {
    align-items: center;
    border: 1px solid $input-border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 -1px;
    padding: 12px 16px;
    width: 100%;

    &:hover {
        background: $light;
    }
}

.fields {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    width: calc(100% - 24px);
}

.two-columns {
    .field {
        flex: 1 1 50%;
    }
}

.three-columns {
    .field {
        flex: 1 1 33.333333%;
    }
}

.field {
    flex: 1 1 auto;

    &:first-child {
        font-weight: 600;
    }
}

.wrapper {
    position: relative;
}

.button {
    @include size(24px);
    background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10a2 2 0 102 2 2 2 0 00-2-2zm-7 0a2 2 0 102 2 2 2 0 00-2-2zm14 0a2 2 0 102 2 2 2 0 00-2-2z"/></svg>') no-repeat center center;
    border: 0;
    display: block;
}

.options {
    @include position(absolute, auto 0 auto auto);
    background: #fff;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    box-shadow: 2px 4px 12px rgba(0,0,0,0.1);
    padding: 8px;
    z-index: 1000;
}

.option {
    background-color: transparent;
    border: 0;
    white-space: nowrap;
}