@import '../../scss/module.scss';

.container {
    display: flex;
    margin: 0 0 48px;

    @include media($until: sm) {
        flex-direction: column;
    }
}

.tight {
    margin-bottom: 0px;
}

.tab {
    background: transparent;
    border: none;
    border-bottom: 2px solid $input-border-color;
    flex: 1 1 auto;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 10px 0;

    @include media($until: sm) {
        width: 100%;
    }
}

.active {
    border-bottom: 2px solid $dark;
    font-weight: 700;

    @include media($until: sm) {
        background: $dark;
        color: #fff;
    }
}