﻿@mixin linkcolor ($link, $visited: $link, $hover: $link, $active: $hover) {
    & {
        color: $link;

        &:visited {
            color: $visited;
        }

        &:hover {
            color: $hover;
        }

        &:active, &:focus {
            color: $active;
        }
    }
}
