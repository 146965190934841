﻿// -----------------------------
// arrows
// -----------------------------

// $direction: top, left, right, bottom, top-left, top-right, bottom-left, bottom-right
// $color: hex, rgb or rbga
// $size: px or em
// $position: true or false - optional (move to overhanging centered edge, message box style)
// @example
// .element{
//   @include arrow(top, #000, 50px, true);
// }

@mixin arrow($direction, $color, $size, $position: false) {
    display: block;
    height: 0;
    width: 0;

    @if $position == true {
        content: "";
        position: absolute;

        @if $direction == "top" {
            bottom: 100%;
            left: 50%;
            margin-left: (-$size);
        } @else if $direction == "bottom" {
            top: 100%;
            left: 50%;
            margin-left: (-$size);
        } @else if $direction == "left" {
            right: 100%;
            top: 50%;
            margin-top: (-$size);
        } @else if $direction == "right" {
            left: 100%;
            top: 50%;
            margin-top: (-$size);
        }
    }

    @if $direction == "top" {
        border-top: $size solid transparent;
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
        border-right: $size solid transparent;
    } @else if $direction == "right" {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == "bottom" {
        border-top: $size solid $color;
        border-bottom: $size solid transparent;
        border-right: $size solid transparent;
        border-left: $size solid transparent;
    } @else if $direction == "left" {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid transparent;
        border-right: $size solid $color;
    } @else if $direction == "top-left" {
        border-top: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == "top-right" {
        border-top: $size solid $color;
        border-left: $size solid transparent;
    } @else if $direction == "bottom-left" {
        border-bottom: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == "bottom-right" {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
}
