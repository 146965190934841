@import '../../../scss/module.scss';

.container {
    display: flex;
    justify-content: center;
    margin: 0 0 16px;

    > div {
        margin: 0 8px;
    }
}