﻿@use "sass:math";

@mixin aspect-ratio($arglist... /*$width/$ratio, $height*/ ) {
    $map: keywords($arglist);
    $height: map-get($map, height) or nth-or-null($arglist, 2);
    $width: map-get($map, width) or nth-or-null($arglist, 1);
    $ratio: map-get($map, ratio) or if($width and $height, math.div($width, $height), nth-or-null($arglist, 1)) or 1;
    $padding: math.div(1, $ratio) * 100%;

    &:before {
        content: '';
        float: left;
        padding-bottom: $padding;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
