.paper {
    border-radius: 5px;
    position: relative;

    @include media($until: md) {
        @include position(fixed, 0 0 0 0);
        border-radius: 0;
        display: flex;
        flex-direction: column;

        &--static {
            display: block;
            position: relative;

            & .paper__content {
                padding-top: 10px;
            }
        }
    }

    &__header {
        @include media($until: md) {
            background: $white;
            border-bottom: 1px solid $input-border-color;
        }
    }

    &__main {
        @include media($until: md) {
            flex: 1 1 auto;
            overflow-y: auto;
        }
    }

    &__heading {
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 2.5rem;
        margin: 0 0 32px;
        padding: 0 24px;
        text-align: center;

        @include media(md) {
            font-size: 2.25rem;
            padding: 0;
        }

        button {
            font-weight: 600;
        }
    }

    &__tabs {
        width: 100%;
        display: flex;
        font-size: 0.875rem;
        flex-wrap: nowrap;
        line-height: 1.375rem;
        border-bottom: 2px solid $lightgrey;
        padding: 0 24px;

        @include media(md) {
            padding: 0 32px;
        }

        @include media($until: md) {
            border: none;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
        }
    }

    &__tab {
        background: inherit;
        border: none;
        border-bottom: 2px solid transparent;
        flex: 1;
        margin-bottom: -2px;
        padding-bottom: 16px;
        text-align: center;
        user-select: none;

        @include media($until: md) {
            margin-bottom: 0;
            margin-right: 16px;
        }

        &::after {
            @include size(14px, 22px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14" height="16"><path fill="#2A3037" d="m295.515 115.716-19.626 19.626c-4.753 4.753-4.675 12.484.173 17.14L356.78 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h344.78l-80.717 77.518c-4.849 4.656-4.927 12.387-.173 17.14l19.626 19.626c4.686 4.686 12.284 4.686 16.971 0l131.799-131.799c4.686-4.686 4.686-12.284 0-16.971L312.485 115.716c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>') no-repeat center;
            content: "";
            display: inline-block;
            margin-left: 8px;
            opacity: 0.3;
            vertical-align: text-bottom;
        }

        &:last-child::after {
            display: none;
        }

        &--active {
            border-color: #3B4147;
            font-weight: 600;

            &::after {
                opacity: 1;
            }
        }

        &--complete {
            border-color: $success;

            &::after {
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#34C759" d="M435.848 83.466 172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>');
                opacity: 1;
                width: 16px;
            }

            &:last-child::after {
                display: inline-block;
            }
        }
    }

    &__infoCard {
        border-radius: 5px;
        background: $lightgrey;
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding: 20px;
        margin: 0 0 50px 0;
    }

    &__infoCard--light {
        border-radius: 5px;
        background: $light;
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding: 20px;
        margin: 0 0 50px 0;
    }

    &__preamble {
        background: $lightgrey;
        border-radius: 5px;
        font-size: 0.875rem;
        line-height: 1.375rem;
        margin: 24px;
        padding: 20px;

        @include media(md) {
            margin: 32px;
        }

        &--unpublished {
            background: $pink;
            padding-left: 64px;
            position: relative;

            &::before {
                @include position(absolute, null null null 20px);
                @include size(29px, 23px);
                background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="29" height="23"><path fill="#D51C29" d="M634 471 36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"/></svg>') no-repeat;
                content: "";
            }
    
        }

        > p:last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        padding: 56px 24px 8px;

        @include media(md) {
            padding: 56px 32px 8px;
        }

        &--preview {
            margin: 0 auto;
            max-width: 462px;
            padding: 24px 24px 8px;
        }

        p {
            font-size: 0.875rem;
            margin: 10px 0 32px;
            max-width: 612px;
        }
    }

    &__back {
        @include position(absolute, 24px null null 32px);
        background: none;
        border: none;
        font-weight: 600;
        font-size: 0.75rem;
        padding-left: 24px;

        &::after {
            @include position(absolute, 4px null null 4px);
            @include chevron(12px, $dark, 2px);
            content: "";
            transform: rotate(135deg) !important;
        }
    }

    &__close {
        @include position(absolute, 24px 32px null null);
        background: none;
        border: none;
        font-weight: 600;
        font-size: 0.75rem;
        padding-right: 24px;

        &::after {
            @include position(absolute, 50% 0 null null);
            @include size(16px, 2px);
            background: $dark;
            content: "";
            transform: rotate(-45deg);
        }

        &::before {
            @include position(absolute, 50% 0 null null);
            @include size(16px, 2px);
            background: $dark;
            content: "";
            transform: rotate(45deg);
        }  
    }

    &__status {
        @include position(absolute, 48px 32px null null);
        color: #53585E;
        font-size: 0.75rem;
    }

    &__buttons {
        border-top: 1px solid $midgrey;
        display: flex;
        justify-content: space-between;
        margin-top: 48px;
        padding: 32px;

        @include media($until: md) {
            margin: 0;
            padding: 24px;
        }
    }

    &__secondary-buttons {
        @include media($until: md) {
            display: none;
        }
    }

    &__primary-buttons {
        @include media($until: md) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__message {
        text-align: center;
        padding: 48px 0 64px;

        h1 {
            font-weight: 600;
            font-size: 2.25rem;
            margin: 8px 0;
        }

        p {
            margin: 16px auto 40px;
            max-width: 344px;
        }

        &--large {
            padding: 0 0 120px;

            p {
                font-size: 1rem;
                margin-top: 10px;
                max-width: 460px;
            }
        }
    }

    &__subheading {
        font-weight: 600;
        font-size: 1.375rem;
        margin: 16px 0 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: hidden;
    }

    &__badge {
        background: $lightgrey;
        color: $black-70;
        line-height: 18px;
        border-radius: 9px;
        padding: 0 8px;
        display: inline;
        font-size: 12px;
    }

    &__fields {
        max-width: 436px;
    }

    &__text {
        max-width: 612px;
    }
}