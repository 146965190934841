@import '../../../scss/module.scss';

.container {
    flex: 1 1 calc(100% / 7);
}

.wrapper {
    overflow-y: clip;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.day {
    background: #fff;
    font-size: 13px;
    padding: 6px 0;
    text-align: center;
}

.schedule {
    position: relative;
    background: rgba(0,0,0,0.09);
    height: 720px;

    &::after {
        @include position(absolute, 0 0 0 null);
        @include size(1px, 100%);
        background: rgba(0,0,0,0.075);
        content: "";
        z-index: 1;
    }

    &:first-child::before {
        @include position(absolute, 0 null null 0);
        @include size(1px, 100%);
        background: rgba(0,0,0,0.075);
        content: "";
        z-index: 1;   
    }
}

.cancelled {
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    margin: 64px 0 0 -1px;
    position: relative;
}

.container:first-child .cancelled::before {
    @include position(absolute, -40px null null null);
    content: 'Avbokade tider:';
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
}

.asset {
    position: relative;
}

.block {
    & + & {
        margin-top: 4px;
    }
}

.subheading {
    font-size: 14px;
    font-weight: 600;
    padding: 0 4px;
}