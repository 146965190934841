﻿// Generic
$white: #fff;

// Design system
$black: #0A1119;
$black-87: #2A3037;
$black-80: #3B4147;
$black-70: #53585E;
$black-60: #6C7075;
$black-38: #A2A4A8;
$black-12: #E1E2E3;
$black-6: #F1F1F2;

// Native simulation
$system-blue-light: #007AFF;

// Theme
$basered: #D51C29;
$dark: $black-87;
$error: #FF3B30;
$light: #F7F8F8;
$lightgrey: #F1F1F2;
$midgrey: #C4C6C8;
$success: #34C759;
$pink: #F9E2E3;

// State-variants
$basered-hover: #eb1e2c;
$basered-active: #940710;

// Mapped
$danger: $error;
$input-border-color: $black-12;
$primary-dark: $dark;