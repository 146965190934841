.boxlist {
    margin: 0 -24px;
    width: 100%;

    @include media(md) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 812px;
        width: 100%;
    }

    & + .boxlist {
        margin-top: 20px;
        padding-top: 20px;
        position: relative;

        &::before {
            @include position(absolute, 0 20px null 20px);
            height: 1px;
            background: $midgrey;
            content: "";
        }
    }

    &__item {
        background: $white;
        border-radius: 5px;
        border: none;
        box-shadow: 0 10px 20px 0 rgba(42,48,55,0.1);
        color: $dark;
        display: block;
        font-weight: 600;
        margin: 20px;
        padding: 32px 36px 32px 112px;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow 0.25s;
        width: 100%;

        @include media(md) {
            width: calc(50% - 40px);
        }

        &--slim {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &--no-image {
            padding-left: 20px;
        }

        &--trial,
        &--valid,
        &--warning,
        &--invalid {
            min-height: 80px;

            &::after {
                position: absolute;
                color: #fff;
                font-size: 14px;
                transform: translate(-50%, -50%) rotate(-90deg);
                left: -11px;
                top: 50%;
            }
        }

        &--valid {
            border-left: 20px solid $success;
            padding-left: 12px;

            &::after {
                content: "AKTIV";
            }
        }

        &--warning {
            border-left: 20px solid #ffcc00;
            padding-left: 12px;
            
            &::after {
                content: "UPPSAGD";
            }
        }

        &--invalid {
            border-left: 20px solid $error;
            padding-left: 12px;
            
            &::after {
                content: "AVSLUTAD";
            }
        }

        &--trial {
            border-left: 20px solid #ffcc00;
            padding-left: 12px;
            
            &::after {
                content: "PROVA PÅ";
            }
        }

        &:hover, &:active, &:focus {
            color: $dark;
        }

        &::before {
            @include chevron(8px, $dark, 1px);
            @include position(absolute, 50% 24px null null);
            content: "";
            transform: translateY(-50%) rotate(-45deg);
        }

        &:hover {
            box-shadow: 0 3px 10px 0 rgba(42,48,55,0.15);
        }

        &--selectable {
            margin-left: 60px;
            width: calc(50% - 80px);

            &::before {
                display: none;
            }
        }

        &--draft {
            font-style: italic;
            font-weight: normal;

            .boxlist__text::after {
                content: " - Utkast";
            }
        }

        &--unpublished {
            .boxlist__text::after {
                content: " - Dold";
                font-style: italic;
                font-weight: normal;
            }
        }
    }

    &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__image {
        @include position(absolute, 0 null 0 0);
        @include size(88px, 100%);
        border-radius: 5px 0 0 5px;
        object-fit: cover;
    }

    &__checkbox {
        @include position(absolute, null null null -40px);
    }
}

.boxlist-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 812px;
    margin: 0 auto;
    padding: 28px 20px;
    width: 100%;
}