.react-calendar {
    background: white;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    line-height: 1.125em;
    max-width: 100%;
    padding: 24px 12px;
    width: 440px;

    &--doubleView {
        width: 700px;

        .react-calendar__viewContainer {
            display: flex;
            margin: -0.5em;

            > * {
                width: 50%;
                margin: 0.5em;
            }
        }
    }

    &,
    & *,
    & *:before,
    & *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    button {
        margin: 0;
        border: 0;
        outline: none;

        &:enabled {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1em;

        button {
            min-width: 44px;
            background: none;

            &:disabled {
                opacity: 0.25;
            }

            // &:enabled {
            //     &:hover,
            //     &:focus {
            //     }
            // }
        }

        &__label {
            text-transform: capitalize;
            font-weight: 600;
        }

        &__next-button,
        &__prev-button {
            color: $primary;
            font-size: 40px;
            transform: translateY(-5px);
        }

        &__next2-button,
        &__prev2-button {
            display: none;
        }
    }

    &__month-view {
        &__weekdays {
            border-bottom: 1px solid $input-border-color;
            font-size: 0.75em;
            font-weight: bold;
            text-align: center;

            &__weekday {
                padding: 4px 8px 12px;
                abbr[title] {
                    text-decoration: none;
                }
            }
        }

        &__weekNumbers {
            .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75em;
                font-weight: bold;
            }
        }

        &__days {
            &__day {
                // &--weekend {
                //     color: rgb(209, 0, 0);
                // }

                &--neighboringMonth {
                    color: rgb(117, 117, 117);
                }
            }
        }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
            padding: 2em 0.5em;
        }
    }

    &__tile {
        background: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        margin-bottom: 8px !important;
        margin-top: 8px !important;
        max-width: 100%;
        padding: 0 8px;
        position: relative;
        text-align: center;

        abbr {
            position: relative;
        }

        &:disabled {
            opacity: 0.25;
        }

        &:enabled {
            &::before {
                @include size(32px);
                border-radius: 16px;
                content: "";
                display: block;
                border: 1px solid $input-border-color;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
            }

            // &:hover,
            // &:focus {
            // }
        }

        &.react-calendar__year-view__months__month:enabled::before,
        &.react-calendar__decade-view__years__year:enabled::before {
            display: none;
        }

        // &--now {
        // }

        &--active {
            background: lighten($basered, 40%);

            &::before {
                display: none !important;
            }

            // &:enabled {
            //     &:hover,
            //     &:focus {
            //     }
            // }
            
            &:first-child::after {
                @include position(absolute, 0 null null 0);
                @include size(8px, 32px);
                border-top: 16px solid white;
                border-right: 8px solid transparent;
                border-left: none;
                border-bottom: 16px solid white;
                content: "";
                display: block;
            }

            &:last-child::after {
                @include position(absolute, 0 0 null null);
                @include size(8px, 32px);
                border-top: 16px solid white;
                border-left: 8px solid transparent;
                border-right: none;
                border-bottom: 16px solid white;
                content: "";
                display: block;
            }
        }

        &--hasActive.react-calendar__year-view__months__month:enabled,
        &--hasActive.react-calendar__decade-view__years__year:enabled {
            background: $basered;
            color: #fff;

            &::before {
                display: none !important;
            }
        }

        &--hasActive,
        &--rangeStart {
            opacity: 1 !important;

            &::before {
                @include size(32px);
                border-color: $primary !important;
                border-radius: 16px;
                content: "";
                display: block !important;
                background: $primary;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
            }

            abbr {
                color: white;
            }

            // &:enabled {
            //     &:hover,
            //     &:focus {
            //     }
            // }
        }

        &--rangeStart {
            background: linear-gradient(to right, transparent 50%, lighten($basered, 40%) 50%, lighten($basered, 40%));
            
            &:last-child::after {
                @include position(absolute, 0 0 null null);
                @include size(8px, 32px);
                border-top: 16px solid white;
                border-left: 8px solid transparent;
                border-right: none;
                border-bottom: 16px solid white;
                content: "";
                display: block;
            }
        }

        &--rangeEnd {
            background: linear-gradient(to right, lighten($basered, 40%), lighten($basered, 40%) 50%, transparent 50%);

            &:first-child::after {
                @include position(absolute, 0 null null 0);
                @include size(8px, 32px);
                border-top: 16px solid white;
                border-right: 8px solid transparent;
                border-left: none;
                border-bottom: 16px solid white;
                content: "";
                display: block;
            }
        }

        &--rangeStart#{&}--rangeEnd {
            background: transparent;
        }
    }

    &--selectRange {
        .react-calendar__tile {
            &--hover {
                background-color: rgb(230, 230, 230);
            }
        }
    }
}
