@import '../../../scss/module.scss';

.container {
    background: $pink;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    color: $basered;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    padding: 3px 4px;
    position: absolute;
    text-align: left;
    width: 100%;

    &.hover {
        background: $basered !important;
        color: #fff;
    }
}

.time {
    font-size: 10px;
    line-height: 10px;
}

.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 12px;
    line-height: 16px;
}

.large {
    font-size: 18px;
}

$colors: #00A2BB, #AD3E8D, #E9641F, #5F725F, #015D82, #8C5829, #0D4159, #6B3C5E, #E5777E, #868686;

@for $i from 1 through length($colors) {
    .color-#{$i - 1} {
        background-color: nth($colors, $i) !important;
        border-color: darken(nth($colors, $i), 5%) !important;
    }
}
