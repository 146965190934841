@keyframes animate-submenu {
    from { transform: translateX(-320px); }
    to { transform: translateX(-276px); }
}

.submenu {
    display: none;

    @include media(md) {
        @include position(absolute, 0 null 0 90px);
        background: $white;
        box-shadow: 0 10px 20px 0 rgba(42,48,55,0.1);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        transform: translateX(-276px);
        transition: transform 0.5s;
        width: 300px;
        z-index: 99;

        &::after {
            @include position(absolute, 0 0 0 0);
            background: #fff;
            content: "";
            opacity: 1;
            transition: opacity 0.25s;
            z-index: 0;
        }

        + .app__content {
            padding-left: 24px;
            transition: padding-left 0.5s;
        }

        &--expanded {
            transform: translateX(0);

            &::after {
                opacity: 0;
                pointer-events: none;
            }

            + .app__content {
                padding-left: 300px;
            }
        }

        &__button {
            @include position(absolute, 42px -16px null null);
            @include size(32px);
            background: $primary;
            border-radius: 16px;
            border: none;
            color: $white;
            overflow: hidden;
            text-indent: -999px;
            z-index: 1;

            &::before {
                @include chevron(10px, $white, 2px);
                @include position(absolute, 11px null null 9px);
                content: "";
                transform: rotate(-45deg);
                transition: transform 0.5s, left 0.5s;

                .submenu--expanded > & {
                    left: 12px;
                    transform: rotate(-45deg) scale(-1);
                }
            }

            &:focus,
            &:hover {
                background: $basered-hover;
            }

            &:active {
                background: $basered-active;
            }
        }

        &__header {
            border-bottom: 1px solid $midgrey;
            font-size: 1.125rem;
            line-height: 1.5rem;
            padding: 34px 24px;
        }

        &__content {
            flex: 1;
            overflow-y: auto;
            padding: 24px;
        }

        &__footer {
            border-top: 1px solid $midgrey;
            padding: 23px 24px 23px;

            > .btn {
                width: 100%;
            }
        }

        &__placeholder {
            align-items: center;
            background: $lightgrey;
            border-radius: 5px;
            display: flex;
            font-size: 0.875rem;
            font-style: italic;
            height: 100%;
            justify-content: center;
            padding: 24px 48px;
        }

        &__links {
            margin: -24px;
        }

        &__link {
            color: $dark;
            display: block;
            max-width: 100%;
            overflow: hidden;
            padding: 24px 32px 24px 24px;
            position: relative;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
                @include chevron(12px, $dark, 1px);
                @include position(absolute, 28px 20px null null);
                content: "";
                transform: rotate(-45deg);
            }

            &:hover {
                background: $light;
                color: $dark;
                
                &::before {
                    border-color: $dark;
                }
            }

            &:active {
                background: $lightgrey;
            }

            &--draft {
                padding-right: 90px;
                
                &::after {
                    @include position(absolute, 26px 35px null null);
                    background: $lightgrey;
                    border-radius: 9px;
                    content: "Utkast";
                    font-size: 0.75rem;
                    line-height: 18px;
                    padding: 0 8px;
                }

                &.submenu__link--current::after,
                &:hover::after {
                    background: $white;
                }
            }
            
            &--unpublished {
                color: #A2A4A8;
                padding-left: 60px;

                &::after {
                    @include position(absolute, 28px null null 24px);
                    @include size(20px, 16px);
                    background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20" height="16"><path fill="#D51C29" d="M634 471 36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"/></svg>') no-repeat;
                    content: "";
                    display: inline-block;
                }
            }

            &--current {
                background: $pink;
                color: $basered;

                &::before {
                    border-color: $basered;
                }
            }
        }

        &__change {
            background: none;
            border: none;
            color: $primary;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            padding: 0 0 0 8px;
            transform: translateY(-1px);
        }
    }
}