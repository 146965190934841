
.large-heading {
    font-size: 1.375rem;
    font-weight: 700;
}

.preamble {
    line-height: 27px;
    margin: 20px auto 40px;
    max-width: 500px;
}
.meta {
    color: fade-out($color: $black-70, $amount: 0.2);
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 18px;
}
h1 {
  color:  var(--bs-dark, #2A3037);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}
h2 {
  color:  var(--bs-dark, #2A3037);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
h3 {
  color:  var(--bs-dark, #2A3037);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
h4 {
  color:  var(--bs-dark, #2A3037);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
p,label {
  color:  var(--bs-dark, #2A3037);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
label {
  letter-spacing: -0.56px;
}