$primary: #ff9900 !default;

#nprogress {
    pointer-events: none;

    &::before {
        @include position(fixed, 0 null null 0);
        @include size(100%, 3px);
        content: "";
        z-index: 2001;
    }

    .bar {
        @include position(fixed, 0 null null 0);
        @include size(100%, 3px);
        background: $primary;
        z-index: 2002;
    }

    .spinner {
        @include position(fixed, 15px 30px null null);
        display: block;
        z-index: 2003;
    }

    .spinner-icon {
        @include size(30px);
        box-sizing: border-box;
        border: solid 4px transparent;
        border-top-color: $primary;
        border-left-color: $primary;
        border-radius: 50%;
    
        -webkit-animation: nprogress-spinner 400ms linear infinite;
        animation: nprogress-spinner 400ms linear infinite;
    }
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}