@import '../../../scss/module.scss';

.images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.images__item {
    @include size(186px);
    background: #eee;
    max-height: 50%;
    object-fit: contain;
}