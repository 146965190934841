@import '../../scss/module.scss';

.container {
    @include position(absolute, 0 null null 0);
    background: #ffeeaa;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-left: 1px solid rgba(0,0,0,0.1);
    border-right: 1px solid rgba(0,0,0,0.1);
    border-radius: 0 0 5px 5px;
    box-shadow: none;
    color: $dark;
    display: flex;
    max-width: 100vw;
    padding: 24px;
    transform: translate(calc(50vw - 50%), -100%);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 1000;

    @include media(xl) {
        max-width: 1000px;
    }
}

.show {
    transform: translate(calc(50vw - 50%), 0);
    box-shadow: 0 10px 20px 0 rgb(42 48 55 / 10%);
}

.content {
    flex: 1;
    font-weight: 600;
    padding-right: 24px;
}

.close-button {
    background: none;
    border: none;
    color: $dark;
    font-weight: 600;
}