.hs-list {
  font-size: 0.875rem;
  padding-bottom: 100px;
  @include media(md) {
    min-width: 600px;
  }
  overflow-x: auto;
  width: 100%;
  &__row {
    display: flex;
    border: 1px solid $input-border-color;
    border-top: none;
    vertical-align: baseline;
    &:hover {
      background: $light;
    }
    & > * {
      padding: 24px 8px;
      
    }
    &:first-child {
      border-top: 1px solid $input-border-color;
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
  &__context-menu {
    margin: 0 8px 0 auto;
    &-btn {
      background: $white;
      height: fit-content;
      position: relative;
      padding: 0;
      outline: none;
      box-shadow: none;
      border: none;
    }
    .dropdown-menu-end {
      transform: translateX(calc(-100% + 32px)) translateY(4px);
    }
  }
  &__file-icon-wrapper {
    min-width: 42px;
  }
  
}