.timefield {
    display: flex;
    align-items: center;

    &__label {
        margin-right: 16px;
    }

    &__control {
        margin-right: 32px;
        width: auto;
    }
}