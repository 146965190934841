.preview {
    background: $white;
    box-shadow: 0 10px 20px 0 rgba(42, 48, 55, 0.2);
    margin: 32px auto;
    padding: 24px 0;
    max-width: 414px;

    @include media($until: md) {
        &:first-child {
            margin-top: 0;
        }
    }

    &__block {
        background: transparent;
        border: none;
        cursor: pointer;
        display: block;
        font-size: 0.875rem;
        padding: 12px 60px 12px 24px;
        position: relative;
        text-align: left;
        user-select: none;
        width: 100%;

        &::after {
            @include position(absolute, 50% 24px null null);
            background: $basered
                svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#fff" d="m491.609 73.625-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>'
                )
                no-repeat 8px 6px;
            border: 2px solid $white;
            border-radius: 17px;
            color: $white;
            content: 'Ändra';
            font-size: 0.75rem;
            line-height: 30px;
            overflow: hidden;
            padding: 0 0 0 30px;
            transform: translateY(-50%);
            transition: width 0.25s;
            white-space: nowrap;
            width: 30px;
        }

        &:hover {
            background-color: $pink;

            &::after {
                background-color: $basered-hover;
                width: 85px;
            }
        }

        &--read-only {
            cursor: default;

            &::after {
                display: none;
            }

            &:hover {
                background-color: inherit;
            }
        }

        &--price,
        &--key,
        &--phone,
        &--hash,
        &--person,
        &--exclamation,
        &--comment,
        &--calendar {
            background-position: top 18px left 24px;
            background-repeat: no-repeat;
            font-size: 0.75rem;
            padding-left: 66px;
        }

        &--price {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#2A3037" d="m289.94 249.05-59.06-16.86c-8.75-2.52-14.88-10.61-14.88-19.7 0-11.3 9.19-20.48 20.47-20.48h36.91c8.24 0 16.08 2.56 22.63 7.32 2.99 2.17 7.22 1.46 9.84-1.16l11.42-11.42c3.5-3.5 2.94-9.22-.99-12.23-12.26-9.41-27.18-14.51-42.9-14.51H272v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24h-3.53c-30.59 0-55.13 26.3-52.24 57.48 2.06 22.16 19.06 40.12 40.45 46.22l56.44 16.11c8.75 2.52 14.88 10.61 14.88 19.7 0 11.3-9.19 20.48-20.47 20.48h-36.91c-8.24 0-16.08-2.56-22.63-7.32-2.99-2.17-7.22-1.46-9.84 1.16l-11.42 11.42c-3.5 3.5-2.94 9.21.99 12.23 12.26 9.41 27.18 14.51 42.9 14.51H240v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h1.36c22.81 0 44.33-13.59 51.72-35.17 10.15-29.65-7.28-59.8-35.14-67.78zM512 256c0-35.5-19.4-68.2-49.6-85.5 9.1-33.6-.3-70.4-25.4-95.5s-61.9-34.5-95.5-25.4C324.2 19.4 291.5 0 256 0s-68.2 19.4-85.5 49.6c-33.6-9.1-70.4.3-95.5 25.4s-34.5 61.9-25.4 95.5C19.4 187.8 0 220.5 0 256s19.4 68.2 49.6 85.5c-9.1 33.6.3 70.4 25.4 95.5 26.5 26.5 63.4 34.1 95.5 25.4 17.4 30.2 50 49.6 85.5 49.6s68.1-19.4 85.5-49.6c32.7 8.9 69.4.7 95.5-25.4 25.1-25.1 34.5-61.9 25.4-95.5 30.2-17.3 49.6-50 49.6-85.5zm-91.1 68.3c5.3 11.8 29.5 54.1-6.5 90.1-28.9 28.9-57.5 21.3-90.1 6.5C319.7 433 307 480 256 480c-52.1 0-64.7-49.5-68.3-59.1-32.6 14.8-61.3 22.2-90.1-6.5-36.8-36.7-10.9-80.5-6.5-90.1C79 319.7 32 307 32 256c0-52.1 49.5-64.7 59.1-68.3-5.3-11.8-29.5-54.1 6.5-90.1 36.8-36.9 80.8-10.7 90.1-6.5C192.3 79 205 32 256 32c52.1 0 64.7 49.5 68.3 59.1 11.8-5.3 54.1-29.5 90.1 6.5 36.8 36.7 10.9 80.5 6.5 90.1C433 192.3 480 205 480 256c0 52.1-49.5 64.7-59.1 68.3z"/></svg>'
            );
        }

        &--key {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#2A3037" d="M336 32c79.529 0 144 64.471 144 144s-64.471 144-144 144c-18.968 0-37.076-3.675-53.661-10.339L240 352h-48v64h-64v64H32v-80l170.339-170.339C195.675 213.076 192 194.968 192 176c0-79.529 64.471-144 144-144m0-32c-97.184 0-176 78.769-176 176 0 15.307 1.945 30.352 5.798 44.947L7.029 379.716A24.003 24.003 0 0 0 0 396.686V488c0 13.255 10.745 24 24 24h112c13.255 0 24-10.745 24-24v-40h40c13.255 0 24-10.745 24-24v-40h19.314c6.365 0 12.47-2.529 16.971-7.029l30.769-30.769C305.648 350.055 320.693 352 336 352c97.184 0 176-78.769 176-176C512 78.816 433.231 0 336 0zm48 108c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"/></svg>'
            );
        }

        &--calendar {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="23" height="23"><path fill="#2A3037" d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"/></svg>'
            );
        }
        &--phone {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M256 240a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm0 128a48 48 0 1 1 48-48 48.05 48.05 0 0 1-48 48zm114.43-175.75A64 64 0 0 0 314.86 160H197.14a64 64 0 0 0-55.57 32.25L36.21 376.62A32 32 0 0 0 32 392.5V448a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32v-55.5a32 32 0 0 0-4.21-15.88zM448 448H64v-55.5l105.36-184.38A32.07 32.07 0 0 1 197.14 192h117.72a32.07 32.07 0 0 1 27.78 16.12L448 392.5zm52.27-329.8C431.72 63.21 344.81 32 256 32S80.28 63.21 11.73 118.2A32.17 32.17 0 0 0 0 143.29V208a16 16 0 0 0 16 16h70.11a16 16 0 0 0 14.31-8.85L128 152c39.9-17.28 83.23-24 128-24 44.77 0 88.08 6.72 128 24l27.58 63.15a16 16 0 0 0 14.31 8.85H496a16 16 0 0 0 16-16v-64.71a32.17 32.17 0 0 0-11.73-25.09zM480 192h-43.61l-23.07-52.81-5-11.55-11.57-5C355.33 104.71 309.3 96 256 96s-99.32 8.71-140.71 26.63l-11.57 5-5 11.55L75.61 192H31.94l-.18-48.84A359.7 359.7 0 0 1 256 64a357.89 357.89 0 0 1 224 79.29z"/></svg>'
            );
        }
        &--person {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M256 32c61.8 0 112 50.2 112 112s-50.2 112-112 112-112-50.2-112-112S194.2 32 256 32m128 320c52.9 0 96 43.1 96 96v32H32v-32c0-52.9 43.1-96 96-96 85 0 67.3 16 128 16 60.9 0 42.9-16 128-16M256 0c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144S335.5 0 256 0zm128 320c-92.4 0-71 16-128 16-56.8 0-35.7-16-128-16C57.3 320 0 377.3 0 448v32c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-32c0-70.7-57.3-128-128-128z"/></svg>'
            );
        }
        &--hash {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M446.381 182.109l1.429-8c1.313-7.355-4.342-14.109-11.813-14.109h-98.601l20.338-113.891C359.047 38.754 353.392 32 345.92 32h-8.127a12 12 0 0 0-11.813 9.891L304.89 160H177.396l20.338-113.891C199.047 38.754 193.392 32 185.92 32h-8.127a12 12 0 0 0-11.813 9.891L144.89 160H42.003a12 12 0 0 0-11.813 9.891l-1.429 8C27.448 185.246 33.103 192 40.575 192h98.6l-22.857 128H13.432a12 12 0 0 0-11.813 9.891l-1.429 8C-1.123 345.246 4.532 352 12.003 352h98.601L90.266 465.891C88.953 473.246 94.608 480 102.08 480h8.127a12 12 0 0 0 11.813-9.891L143.11 352h127.494l-20.338 113.891C248.953 473.246 254.608 480 262.08 480h8.127a12 12 0 0 0 11.813-9.891L303.11 352h102.886a12 12 0 0 0 11.813-9.891l1.429-8c1.313-7.355-4.342-14.109-11.813-14.109h-98.601l22.857-128h102.886a12 12 0 0 0 11.814-9.891zM276.318 320H148.825l22.857-128h127.494l-22.858 128z"/></svg>'
            );
        }
        &--exclamation {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"/></svg>'
            );
        }
        &--comment {
            background-image: svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M256 64c123.5 0 224 79 224 176S379.5 416 256 416c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176m0-32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26 3.8 8.8 12.4 14.5 22 14.5 61.5 0 110-25.7 139.1-46.3 29 9.1 60.2 14.3 93 14.3 141.4 0 256-93.1 256-208S397.4 32 256 32z"/></svg>'
            );
        }
    }

    &__file-block {
        font-size: 0.875rem;
        padding: 12px 24px;

        &--accept {
            outline: 1px dashed $success;
        }

        &--reject {
            outline: 1px dashed $error;
        }
    }

    &__divider {
        @include size(100%, 1px);
        background: $midgrey;
        margin-bottom: 24px;
    }

    &__image-block {
        cursor: inherit;
        font-size: 0.875rem;
        padding: 0 24px 12px;
        position: relative;

        .custom-tooltip {
            @include position(absolute, 16px 32px null null);
        }

        &--with-hover {
            cursor: pointer;

            &::after {
                @include position(absolute, null 36px 30px null);
                background: $basered
                    svg-url(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#fff" d="m491.609 73.625-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>'
                    )
                    no-repeat 12px 6px;
                border-radius: 17px;
                border: 2px solid $white;
                color: $white;
                content: 'Ändra';
                font-size: 0.75rem;
                line-height: 30px;
                padding: 0 20px 0 36px;
                pointer-events: none;
                white-space: nowrap;
            }

            &:hover::after {
                background-color: $basered-hover;
            }
        }

        &--no-pointer {
            cursor: default;
        }

        &--empty{
            &::after {
                display: none;
            }

            .preview__image {
                opacity: 0.2;
            }
        }

        &--thumb {
            &:nth-child(2n) {
                padding-right: 6px;
            }

            &:nth-child(2n + 1) {
                padding-left: 6px;
            }

            &.preview__image-block--with-hover{
                &::after {
                    background-position: 7px 6px;
                    content: "Ändra";
                    height: 32px;
                    overflow: hidden;
                    padding: 0 0 0 32px;
                    right: 18px;
                    transition: width 0.25s;
                    width: 32px;
                }

                &:hover::after {
                    width: 84px;
                }
            }

            
            &.preview__image-block--with-hover:nth-child(2n + 1)::after {
                right: 36px;
            }
        }
    }

    &__image-wrapper {
        @include aspect-ratio(600, 430);
        background: inherit;
        border: none;
        position: relative;
        width: 100%;
    }

    &__image {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        border-radius: 5px;
        object-fit: cover;

    }

    &__image-delete {
        cursor: pointer;
        @include position(absolute, 8px 8px null null);
        @include size(24px);
        background: $basered;
        border-radius: 50%;
        border: 2px solid $white;
        &::after {
            @include position(absolute, 0 0 0 0);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="10" height="15"><path fill="#fff" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/></svg>'
                )
                no-repeat center;
            content: '';
            pointer-events: none;
        }

        &:hover {
            background-color: $basered-hover;
        }
    }

    &__no-image {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        background: center no-repeat;
        background-size: contain;
        border: 1px dashed $midgrey;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        &--accept {
            border: 2px dashed $success;
        }

        &--reject {
            border: 2px dashed $error;
        }
    }

    &__dropzone {
        &--accept .btn {
            background-color: lighten($success, 45%);
        }
    }

    &__image-heading {
        font-size: 1rem;
        font-weight: 600;
    }

    &__image-text {
        font-size: 0.875rem;

        p {
            max-width: 252px;
            margin: 8px auto 24px;
        }
    }

    &__heading {
        font-size: 1.375rem;
        font-weight: 600;
    }

    &__block-heading {
        font-size: 0.875rem;
        font-weight: 600;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding-left: 30px;

        li {
            position: relative;
            line-height: 23px;
            margin: 5px 0;

            &::before {
                @include position(absolute, 0 null null -30px);
                @include size(21px, 23px);
                background: svg-url(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21" height="23"><path fill="#2A3037" d="M413.505 91.951 133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>'
                    )
                    no-repeat;
                content: '';
            }
        }
    }

    &__required {
        color: $error;
        font-size: 0.75rem;
        font-style: italic;
        margin: -12px 24px 0;

        &--indent {
            margin-left: 66px;
        }

        .preview__block:hover + & {
            visibility: hidden;
        }
    }

    &__default {
        font-style: italic;
        opacity: 0.5;
    }

    &__dropdown-menu {
        background-color: #ffffff;
        border-radius: 5px;
        border: none;
        box-shadow: 0 10px 20px 0 rgba(42, 48, 55, 0.2);
        margin-top: -10px;
        right: 40px;
    }

    &__blocker {
        @include position(absolute, 0 0 0 0);
        background: rgba(255, 255, 255, 0.75);
        z-index: 1;

        &::after {
            @include position(absolute, calc(50% - 15px) null null calc(50% - 15px));
            @include size(30px);
            box-sizing: border-box;
            border: solid 4px transparent;
            border-top-color: $primary;
            border-left-color: $primary;
            border-radius: 50%;
            content: "";
            display: block;
        
            -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
        }
    }
}

.edit-this {
    border: inherit;
    background: inherit;
    position: relative;

    &::after {
        @include position(absolute, 50% null null 100%);
        background: $basered
            svg-url(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#fff" d="m491.609 73.625-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>'
            )
            no-repeat 8px 6px;
        border: 2px solid $white;
        border-radius: 17px;
        color: $white;
        content: 'Ändra';
        font-size: 0.75rem;
        line-height: 30px;
        overflow: hidden;
        padding: 0 0 0 30px;
        text-align: left;
        transform: translateY(-50%);
        transition: width 0.25s;
        white-space: nowrap;
        width: 30px;

        @include media($until: md) {
            left: auto;
            right: -28px;
        }
    }

    &:hover {
        &::after {
            background-color: $basered-hover;
            width: 85px;
        }
    }
}
