.menu {
    background: $primary-dark;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90px;
    z-index: 100;

    &__logo {
        display: block;
        margin: 40px auto;
    }

    &__item {
        border-left: 4px solid transparent;
        color: $white;
        display: block;
        font-size: 0.75rem;
        line-height: 1;
        padding: 18px 5px 18px 1px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.5s, border-left-color 0.25s;

        &:active, &:focus, &:hover {
            color: $white;
            text-decoration: none;
        }

        &:last-child {
            margin-bottom: 10px;
        }

        > svg {
            display: block;
            margin: 0 auto 8px;
        }

        &--active {
            background: #3B4147;
            border-left-color: $white;
        }
    }

    &__badge {
        background-color: $basered;
        border-radius: 0.25rem;
        color: #fff;
        display: inline-block;
        font-size: 0.75em;
        font-weight: 700;
        line-height: 1;
        padding: 0.35em 0.65em;
        position:absolute;
        right:10px;
        text-align: center;
        top:10px;
        vertical-align: baseline;
        white-space: nowrap;
    }
}