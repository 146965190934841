.recipient {
    display: flex;

    &__input {
        background-clip: padding-box;
        background-color: #fff;
        border-radius: 0.25rem;
        border: 1px solid $input-border-color;
        color: $dark;
        flex: 1;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        max-width: 300px;
        overflow: hidden;
        padding: 12px 16px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__change, &__delete {
        background: inherit;
        border: none;
        color: $basered;
        font-size: 0.75rem;
        font-weight: 600;
        margin-left: 16px;
        text-decoration: none;
    }

    &__change {
        &::before {
            @include size(16px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#D51C29" d="m493.255 56.236-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"/></svg>');
            content: "";
            display: inline-block;
            margin-right: 8px;
            vertical-align: top;
        }
    }

    &__delete {
        white-space: nowrap;

        &::before {
            @include size(16px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="16"><path fill="#D51C29" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>');
            content: "";
            display: inline-block;
            margin-right: 8px;
            vertical-align: top;
        }
    }
}