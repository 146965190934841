.btn {
    letter-spacing: 0.5px;

    &:disabled {
        background: $midgrey;
        border-color: $midgrey;
    }
}

.btn + .btn {
    margin-left: 10px;
}

.btn-primary {
    &:focus,
    &:hover {
        background: $basered-hover;
        border-color: $basered-hover;
    }

    &:active {
        background: $basered-active;
        border-color: $basered-active;
    }

    &.btn-next {
        padding-left: 24px;
        padding-right: 48px;
        position: relative;

        &::after {
            @include chevron(8px, $white, 2px);
            @include position(absolute, 50% 24px null null);
            content: '';
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    &.btn-icon-hide {
        padding-left: 44px;
        padding-right: 24px;

        &::before {
            @include size(20px, 16px);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20" height="16"><path fill="#fff" d="M634 471 36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"/></svg>'
                )
                no-repeat;
            content: '';
            display: inline-block;
            margin-left: -24px;
            margin-right: 8px;
            transition: background-image 0.15s;
            transform: translateY(2px);
        }
    }

    &.btn-icon-edit {
        padding-left: 44px;
        padding-right: 24px;

        &::before {
            @include size(16px, 16px);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="#fff" d="m491.609 73.625-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>'
                )
                no-repeat;
            content: '';
            display: inline-block;
            margin-left: -24px;
            margin-right: 8px;
            transition: background-image 0.15s;
            transform: translateY(2px);
        }
    }

    &.btn-icon-publish {
        padding-left: 44px;
        padding-right: 24px;

        &::before {
            @include size(18px, 16px);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="18" height="16"><path fill="#fff" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"/></svg>'
                )
                no-repeat;
            content: '';
            display: inline-block;
            margin-left: -24px;
            margin-right: 8px;
            transition: background-image 0.15s;
            transform: translateY(2px);
        }
    }

    &.btn-cross {
        padding-right: 24px;
        padding-left: 48px;
        position: relative;

        &::after {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $white;
            content: '';
            transform: rotate(-45deg);
        }

        &::before {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $white;
            content: '';
            transform: rotate(45deg);
        }
    }

    &.btn-plus {
        position: relative;

        &::after {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $white;
            content: '';
        }

        &::before {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $white;
            content: '';
            transform: rotate(90deg);
        }

        &:hover::before,
        &:active::before,
        &:focus::before,
        &:hover::after,
        &:active::after,
        &:focus::after {
            background-color: $white;
        }
    }
}

.btn-success {
    color: #fff;

    &:hover,
    &:active,
    &:focus,
    &:disabled,
    &.disabled {
        color: #fff;
    }
}

.btn-secondary {
    background: none;
    border: 1px solid $primary;
    color: $primary;

    &:focus,
    &:hover {
        background: $basered-hover;
        border-color: $basered-hover;
        color: #fff;
    }

    &:active {
        background: $basered-active;
        border-color: $basered-active;
        color: #fff;
    }
    &:disabled {
        background: transparent;
        border-color: #a2a4a8;
        color: #a2a4a8;
    }

    &.btn-icon-delete {
        padding-left: 44px;
        padding-right: 24px;

        &::before {
            @include size(14px, 16px);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14" height="16"><path fill="#D51C29" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>'
                )
                no-repeat;
            content: '';
            display: inline-block;
            margin-left: -24px;
            margin-right: 8px;
            transition: background-image 0.15s;
            transform: translateY(2px);
        }

        &:hover,
        &:active,
        &:focus {
            &::before {
                background-image: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14" height="16"><path fill="#fff" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>'
                );
            }
        }

        &.btn-disabled,
        &:disabled {
            background: transparent;
            border-color: #a2a4a8;
            color: #a2a4a8;

            &::before {
                background-image: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14" height="16"><path fill="#A2A4A8" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>'
                );
            }
        }
    }

    &.btn-icon-upload {
        &::before {
            @include size(23px);
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#D51C29" d="M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"/></svg>'
                )
                no-repeat;
            content: '';
            display: inline-block;
            margin-left: -24px;
            margin-right: 16px;
            transition: background-image 0.15s;
            vertical-align: sub;
        }

        &:hover,
        &:active,
        &:focus {
            &::before {
                background-image: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#fff" d="M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"/></svg>'
                );
            }
        }
        &:disabled::before {
            background: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#DA2A4A8" d="M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"/></svg>'
                )
                no-repeat;
        }
    }

    &.btn-cross {
        padding-right: 24px;
        padding-left: 48px;
        position: relative;

        &::after {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $basered;
            content: '';
            transform: rotate(-45deg);
        }

        &::before {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $basered;
            content: '';
            transform: rotate(45deg);
        }

        &:hover::before,
        &:active::before,
        &:focus::before,
        &:hover::after,
        &:active::after,
        &:focus::after {
            background-color: $white;
        }
    }

    &.btn-plus {
        position: relative;

        &::after {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $basered;
            content: '';
        }

        &::before {
            @include position(absolute, 50% null null 18px);
            @include size(16px, 2px);
            background: $basered;
            content: '';
            transform: rotate(90deg);
        }

        &:hover::before,
        &:active::before,
        &:focus::before,
        &:hover::after,
        &:active::after,
        &:focus::after {
            background-color: $white;
        }
    }

    &.btn-prev {
        padding-left: 48px;
        padding-right: 24px;
        position: relative;

        &::after {
            @include chevron(8px, $basered, 2px);
            @include position(absolute, 50% null null 24px);
            content: '';
            transform: translateY(-50%) rotate(-225deg);
        }

        &:hover::after,
        &:active::after,
        &:focus::after {
            border-color: $white;
        }
    }
}

.btn-less-padding {
    padding-left: 24px;
    padding-right: 24px;
}

.btn-lg {
    line-height: 48px;
}

.btn-shadow {
    box-shadow: 0 6px 15px 0 rgba(42, 48, 55, 0.2);
}

.field-error {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 6px;
}

.form-check {
    cursor: pointer;
}

.form-check-label {
    font-size: 0.875rem;
}

.form-check-input--checkmark {
    background-image: svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="13" height="13"><path fill="#fff" d="m173.898 439.404-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>'
    ) !important;
    background-size: unset !important;
    cursor: pointer;
}

.radio-group.is-invalid {
  & .form-check-input {
    border-color: #FF3B30;
  }
  & .form-check-label {
    color: #FF3B30;
  }
}

.form-switch {
    padding-left: 3rem;

    > .form-check-input {
        margin-left: -3rem;
        width: 2.5rem;
    }
}

.form-count {
    @include position(absolute, null 24px null null);
    font-size: 0.75rem;
    margin-top: -18px;
}
