@import '../../../scss/module.scss';

.container {
    background: #fff;
    border-bottom: 1px solid #dadada;
    position: absolute;
    width: 100%;
}

.start, .end {
    color: #A2A3A3;
    font-size: 12px;
    position: absolute;
}

.start {
    top: 1px;
    left: 2px;
}

.end {
    bottom: 2px;
    right: 4px;
}

.large {
    font-size: 18px;
    line-height: 14px;
}