@import '../../scss/module.scss';

$success: #66cc00 !default;
$error: #ee2200 !default;

.custom-toaster {
    @include position(fixed, null 30px 0px null);
    z-index: 1004;
}

.custom-toast {
    overflow: hidden;
    user-select: none;

    &__content {
        background: #fff;
        border: 1px solid #eaeaea;
        border-top: 10px solid $success;
        box-shadow: 0 13px 6px -8px rgba(0,0,0,0.05);
        font-size: 14px;
        margin-bottom: 15px;
        padding: 10px 15px;
        width: 400px;

        &--error {
            border-top-color: $error;
        }

        &--static {
            background: #ffeeaa;
            border-top: none;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            border-left: 1px solid rgba(0,0,0,0.1);
            border-right: 1px solid rgba(0,0,0,0.1);
        }
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
    }

    &__close {
        background: none;
        border: none;
        color: $dark;
        cursor: pointer;
        float: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        &:hover {
            color: #000;
        }
    }
    
    // Animations

    &--enter {
        max-height: 0;
    }

    &--enter-active {
        max-height: 1000px;
        transition: max-height 1000ms ease-in;
    }

    &--exit {
        max-height: 300px;
    }

    &--exit-active {
        max-height: 0;
        transition: max-height 2000ms ease-in;
    }
}