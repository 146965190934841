﻿/* NOTE: For getting proper SASS-variables use https://bootstrap.build/app */

$enable-negative-margins: true;

/* Colours */
$primary: $basered;

/* Colour assignments */
$body-bg: $light;
$body-fg: $primary-dark;

/* Typography */
$body-color: $primary-dark;
$font-family-sans-serif: $font-family-sans-serif;
$headings-color: $primary-dark;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: bold;

/* Inputs */
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-width: 1.5rem;
$form-check-label-cursor: pointer;
$form-check-padding-start: 2rem;
$form-label-font-size: 0.875rem;
$input-bg: $white;
$input-border-color: $input-border-color;
$input-btn-focus-box-shadow: 0 0 0 0.25rem rgb($primary, 20%);
$input-focus-border-color: #6c7075;
$input-padding-x: 16px;
$input-padding-y: 12px;

/* Buttons */
$btn-border-radius: 20px;
$btn-font-weight: 600;
$btn-font-size: 0.875rem;
$btn-line-height: 40px;
$btn-padding-x: 24px;
$btn-padding-y: 0;
$btn-disabled-opacity: 1;
$btn-border-radius-lg: 24px;
$btn-font-size-lg: 1rem;
$btn-padding-x-lg: 56px;
$btn-padding-y-lg: 0;
