.checkdown {
    position: relative;

    @include media(md) {
      width: 235px;
    }

    @include media($until: md) {
      width: 100%;
    }

    &__button {
        background: $white;
        border-radius: 5px;
        border: 1px solid $white;
        box-shadow: 0 10px 20px 0 rgba(42,48,55,0.1);
        display: block;
        font-size: 0.875rem;
        padding: 16px 32px 16px 16px;
        position: relative;
        text-align: left;
        width: 100%;

        &::after {
            @include chevron(8px, $dark, 1px);
            @include position(absolute, 50% 24px null null);
            content: "";
            transform: translateY(-50%) rotate(45deg);
        }

        &--expanded {
            border-color: $dark;

            &::after {
                transform: translateY(-50%) rotate(225deg);
            }
        }
    }

    &__list {
        background: $white;
        border-radius: 5px;
        box-shadow: 0 10px 20px 0 rgba(42,48,55,0.1);
        list-style: none;
        margin-top: 8px;
        padding: 8px 0;
        position: relative;

        &--floating {
            position: absolute;
            width: 100%;
            z-index: 999;
        }

        &--fixed {
            @include media(md) {
                max-height: 190px;
                overflow-y: auto;
                position: fixed;
                width: 235px;
            }
        }
    }

    &__item {
        &:hover {
            background: $pink;
        }

        .form-check-input {
            margin-left: -16px;
            margin-top: 6px;
        }

        .form-check-label {
            display: block;
            padding: 8px 16px 8px;
        }
    }
}