.admins {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.875rem;
    margin-top: 30px;
    margin-bottom: 100px;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    overflow-x: auto;
    width: 100%;

    &__head {
        background: $black-6;
        border-bottom: 1px solid $input-border-color;

        &--desktop {
            @include media($until: md) {
                display: none;
            }
        }

        &--mobile {
            @include media(md) {
                display: none !important;
            }
        }
    }

    &__header {
        cursor: pointer;
        padding: 20px 8px;

        &:first-child {
            width: 36px;
        }

        &--ascending::after,
        &--descending::after,
        &--no-sort:hover::after {
            @include size(8px, 19px);
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="8" height="13"><path fill="#0A1119" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>') no-repeat center center;
            content: "";
            display: inline-block;
            margin-left: 5px;
            vertical-align: text-top;
        }

        &--descending::after {
            transform: rotate(180deg);
        }

        &--no-sort:hover::after {
            background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="8" height="13"><path fill="#A2A4A8" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>') no-repeat center center;
        }
    }

    &__row {
        cursor: pointer;
        border-bottom: 1px solid $input-border-color !important;
        border-bottom-width:1px;
        &:hover {
            background: $light;
        }

        &--separator {
            height: 1px;
            background: $input-border-color;
            width: 100%;
            display: block;
        }

        td {
            padding: 16px 8px;
            &:first-child {
                margin-left: 12px;
            }
        }

        &-expanded {
            background: $light;
            font-size: 0;
            box-shadow: inset 0 10px 10px -10px rgba(42, 48, 55, 0.1), inset 0 -10px 10px -10px rgba(42, 48, 55, 0.1);

            &--show {
                td {
                    height: 74px !important;
                    font-size: 0.875rem;
                    line-height: var(--bs-body-line-height, 1.5) !important;
                }

                & .bookings__row-actions {
                    display: block;
                }
            }

            td {
                overflow: hidden;
                height: 0;
                line-height: 0;
                padding: 0px 8px;
                vertical-align: baseline;
            }
        }

        &-actions {
            position: relative;
            height: 86px;
            display: none;

            &>div {
                position: absolute;
                top: 50%;
                transform: translateY(-16px);
                left: 0;
                justify-content: center;
                align-items: center;

                &>button {
                    white-space: nowrap;
                }
            }
        }
    }

    &__toggle {
        @include size(16px);
        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="8" height="16"><path fill="#6C7075" d="M24.707 38.101 4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"/></svg>') no-repeat center center;
        border: none;
        display: block;

        &--expanded {
            transform: rotate(90deg);
        }
    }

    &__expanded {
        background: $light;
        box-shadow: inset 0 10px 10px -10px rgba(42, 48, 55, 0.1), inset 0 -10px 10px -10px rgba(42, 48, 55, 0.1);
        padding: 16px 32px 16px 44px;

        &--desktop {
            @include media($until: md) {
                display: none;
            }
        }

        &--mobile {
            @include media(md) {
                display: none !important;
            }
        }
    }

    &__extra-content--desktop {
        display: flex;
    }

    &__extra-value {
        margin-right: 40px;
        line-height: 1.75;
    }

    &__actions {
        display: flex;
    }

    &__status {
        color: $black-60;
        margin-right: 24px;
    }

    &__phone-number {
        color: #2A3037;
        text-decoration: none;
    }

    &__filter {
        .checkdown__button {
            border: 1px solid $input-border-color;
            box-shadow: none;
        }
    }
}

.addGuestAdmin {
    p {
        font-size: 14px;
    }
    input {
        margin-bottom: 20px;
        width: 70%;
    }

}

.copyLinkLabel {
    display: flex;
    align-items: center;
    
    &__iconBtn{
        color: #2A3037;
        border: none;
        padding: 20px;
        border-radius: 5px;
        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#000" d="M 433.941 65.941 l -51.882 -51.882 A 48 48 0 0 0 348.118 0 H 176 c -26.51 0 -48 21.49 -48 48 v 48 H 48 c -26.51 0 -48 21.49 -48 48 v 320 c 0 26.51 21.49 48 48 48 h 224 c 26.51 0 48 -21.49 48 -48 v -48 h 80 c 26.51 0 48 -21.49 48 -48 V 99.882 a 48 48 0 0 0 -14.059 -33.941 Z M 352 32.491 a 15.88 15.88 0 0 1 7.431 4.195 l 51.882 51.883 A 15.885 15.885 0 0 1 415.508 96 H 352 V 32.491 Z M 288 464 c 0 8.822 -7.178 16 -16 16 H 48 c -8.822 0 -16 -7.178 -16 -16 V 144 c 0 -8.822 7.178 -16 16 -16 h 80 v 240 c 0 26.51 21.49 48 48 48 h 112 v 48 Z m 128 -96 c 0 8.822 -7.178 16 -16 16 H 176 c -8.822 0 -16 -7.178 -16 -16 V 48 c 0 -8.822 7.178 -16 16 -16 h 144 v 72 c 0 13.2 10.8 24 24 24 h 72 v 240 Z"/></svg>') no-repeat;
        background-position: center;
        &:hover{
            background-color: white;
        }
    }
}
