@import '../../scss/module.scss';

.container {
    margin: 0 auto;
    max-width: 720px;
    padding: 24px;
        
    h3 {
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.75rem;
        margin: 0 0 8px;
    }

    ul {
        margin: 0 0 24px;
        padding-left: 24px;
    }

    li {
        font-size: 0.875rem;
        margin: 12px 0;
    }
}

.heading {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin: 32px 0 32px;

    @include media(md) {
        font-size: 2.25rem;
        padding: 0;
    }
}

.subheading {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 3rem;
    margin: 32px 0 16px;
}
