$icons: (
    icon__ellipsis-vertical:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 7a2 2 0 10-2-2 2 2 0 002 2zm0 10a2 2 0 102 2 2 2 0 00-2-2zm0-7a2 2 0 102 2 2 2 0 00-2-2z"/></svg>',
    icon__ellipsis-horizontal:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10a2 2 0 102 2 2 2 0 00-2-2zm-7 0a2 2 0 102 2 2 2 0 00-2-2zm14 0a2 2 0 102 2 2 2 0 00-2-2z"/></svg>',
);

@each $icon in $icons {
    $name: nth($icon, 1);
    $svg: nth($icon, 2);

    %#{$name} {
        background-image: svg-url($svg);
        background-repeat: no-repeat;
        background-position: center center;
    }
}

img.img__icon {
    @include size(56px);
    min-width: 56px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    &--small {
        @include size(24px);
        min-width: 24px;
    }
    &--large {
        @include size(88px);
        min-width: 88px;
    }
}
