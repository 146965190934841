.app {
    display: flex;
    height: 100vh;

    @include media($until: md) {
        flex-direction: column;
    }

    &__header {
        display: none;

        @include media(md) {
            display: flex;
        }
    }

    &__content {
        flex: 1;
        overflow-y: auto;
    }

    &__inner {
        margin: 0 auto;
        max-width: 1100px;
        padding: 24px;

        &--center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: calc(100vh - 90px);

            @include media(md) {
                min-height: 100vh;
            }
        }

        &--paper {
            background: $white;
            box-shadow: 0 10px 20px 0 rgba(42,48,55,0.1);
            margin: 45px auto;
            max-width: 960px;
            padding: 64px 0 0;

            @include media($until: md) {
                margin: 0;
            }
        }
    }
}